import { BufferAttribute, LinearInterpolant, Vector3 } from "three";

const getRootBone = (rootNode) => {
  const rootBones = [];

  rootNode.traverse((object) => {
    if (object.isBone && (!object.parent || !object.parent.isBone)) {
      rootBones.push(object);
    }
  });

  if (rootBones.length !== 1) {
    throw new Error(
      `THREE.SkeletonUtils: Expected 1 root bone, found ${rootBones.length}.`,
    );
  }

  return rootBones[0];
};

export const getRootMotionInterpolant = (rootNode, targetClip) => {
  // Find animation track that modifies the root bone position
  const rootBone = getRootBone(rootNode);
  const trackName = (rootBone.name || rootBone.uuid) + ".position";
  const track = targetClip.tracks.find((track) => track.name === trackName);

  const values = new BufferAttribute(track.values, 3);
  const itemsPerKeyframe = track.getValueSize() / 3; // 1 (value), or 3 (inTan,value,outTan)
  const itemOffset = itemsPerKeyframe === 3 ? 1 : 0;

  const startPosition = new Vector3().fromBufferAttribute(values, itemOffset);

  const position = new Vector3();

  for (let i = 0; i < track.times.length; i++) {
    // Adjust root-motion so it starts at the model's actual position (the
    // tumbleweed animations are centered on the current position)
    position.fromBufferAttribute(values, i + itemOffset);
    position.sub(startPosition);
    values.setXYZ(i, position.x, position.y, position.z);
  }

  // Create an interpolant matching the root motion
  const interpolant = new LinearInterpolant(
    [...track.times],
    [...track.values],
    track.getValueSize(),
  );

  // Now that we have the interpolant, remove all root motion
  track.values.fill(0);

  return interpolant;
};
