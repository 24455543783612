/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

export function CabinMedium({ hoverAnimation = {}, onClick, ...props }) {
  const { nodes, materials } = useGLTF("/models/cabin-medium.glb");

  return (
    <group {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onClick={onClick}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_main_roof.geometry}
          material={materials.MAT_cabin2_roof}
          position={[0.031, 0.355, -0.039]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_door_trim.geometry}
          material={materials.MAT_cabin2_trim}
          position={[0.006, 0.167, -0.292]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_roof_trim.geometry}
          material={materials["MAT_cabin_log.001"]}
          position={[0.032, 0.526, -0.04]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_door001.geometry}
          material={materials.MAT_cabin_door}
          position={[0.059, 0.11, -0.3]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_porch_roof.geometry}
          material={materials.MAT_house_porch_roof}
          position={[0.024, 0.314, -0.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin2_porch.geometry}
          material={materials.MAT_house_porch}
          position={[0.023, 0.04, -0.365]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin2_porch_steps.geometry}
          material={materials.MAT_house_porch_steps}
          position={[0.023, 0.027, -0.469]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin2_chimney.geometry}
          material={materials.MAT_pipe}
          position={[-0.259, 0.44, 0.077]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin2_roof_snow.geometry}
          material={materials.sneeuw}
          position={[-0.085, 0.327, -0.053]}
        />
        <group position={[-0.357, 0.183, -0.054]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002.geometry}
            material={materials.MAT_window}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Cube002_1.geometry}
            material={materials.MAT_cabin_window_trim}
          />
        </group>
      </motion.group>
    </group>
  );
}

useGLTF.preload("/models/cabin-medium.glb");

CabinMedium.propTypes = {
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
