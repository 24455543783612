import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const sendBroadcastChannel = new BroadcastChannel("storage-update");
const receiveBroadcastChannel = new BroadcastChannel("storage-update");

const INTERVAL_MS = 5000;
const GRACE_SECONDS = 10;

const isActive = (entry, adjust = 0) => {
  return !entry.expiresAt || Date.now() / 1000 < entry.expiresAt - adjust;
};

const getEntry = (keyName) => {
  const value = window.localStorage.getItem(keyName);
  if (value) {
    return JSON.parse(value);
  }

  return;
};

export const useLocalStorage = (
  keyName,
  { defaultValue, expiresAt, expiredCb } = {},
) => {
  const listenerId = useRef(uuidv4());
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const entry = getEntry(keyName);
      if (entry) {
        if (isActive(entry)) {
          return entry.value;
        } else {
          window.localStorage.removeItem(keyName);
        }
      }

      if (defaultValue !== undefined) {
        window.localStorage.setItem(
          keyName,
          JSON.stringify({ value: defaultValue, expiresAt }),
        );
      }

      return defaultValue;
    } catch {
      return defaultValue;
    }
  });

  const setValue = (newValue, expiresAt) => {
    try {
      if (newValue === undefined) {
        window.localStorage.removeItem(keyName);
      } else {
        if (!expiresAt) {
          const entry = getEntry(keyName);
          expiresAt = entry?.expiresAt;
        }
        window.localStorage.setItem(
          keyName,
          JSON.stringify({ value: newValue, expiresAt }),
        );
      }
    } catch (error) {
      console.log(error);
    }

    setStoredValue(newValue);
    sendBroadcastChannel.postMessage({
      keyName,
      newValue,
      sender: listenerId.current,
    });
  };

  useEffect(() => {
    if (expiredCb) {
      const pruneIntervalId = setInterval(() => {
        const entry = getEntry(keyName);
        if (entry && !isActive(entry, GRACE_SECONDS) && expiredCb(entry)) {
          setValue();
        }
      }, INTERVAL_MS);

      return () => clearInterval(pruneIntervalId);
    }
  }, [expiredCb]);

  useEffect(() => {
    const handler = ({
      data: { keyName: receivedKeyName, newValue, sender },
    }) => {
      if (receivedKeyName === keyName && sender !== listenerId.current) {
        setStoredValue(newValue);
      }
    };

    receiveBroadcastChannel.addEventListener("message", handler);

    return () => {
      receiveBroadcastChannel.removeEventListener("message", handler);
    };
  }, []);

  return [storedValue, setValue];
};
