import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import {
  CustomGoalsContainer,
  GoalsContainer,
} from "@features/goals/components";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ErrorToast,
  Loader,
} from "@features/ui";
import { GoalsStartOverConfirmationModal } from "@pages/journey-map/views/goals-start-over-confirmation.modal";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { useCareerPlan } from "@features/career-details/api/use-career-plan";
import { useCreateCareerPlanGoal } from "@features/goals/api/use-create-career-plan-goal";
import { useGoals } from "@features/goals/api/use-goals.js";
import { useSubmitCareerPlan } from "@features/goals/api/use-submit-career-plan";
import { useSyncState } from "@hooks/use-sync-state";
import { useStartOver } from "@pages/journey-map/api/use-start-over";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";

import { buttonTracking } from "@lib/tracking.helpers";

import "./index.scss";

export const Goals = ({ onClose, onRemovePlan, onSubmit }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState();
  const [customGoal, setCustomGoal] = useSyncState("customGoal");
  const onCareerPlanGoalCreated = (_, submittedGoal) => {
    if (submittedGoal.type === "custom") {
      setCustomGoal();
    }
  };

  const {
    data: {
      id: careerPlanId,
      careerId,
      draftGoals = {},
      goals,
      career,
      submittedAt: careerPlanSubmittedAt,
    },
  } = useCareerPlan();

  const { isFetching } = useUserJourney();

  const { isStartingOver, startOver } = useStartOver();

  const { data: goalsByType, isError: isGoalsByTypeError } = useGoals(careerId);
  const { isPending: submittingGoal, mutateAsync: submitGoal } =
    useCreateCareerPlanGoal(careerPlanId, onCareerPlanGoalCreated);
  const {
    isPending: submittingCareerPlan,
    mutateAsync: submitCareerPlan,
    isError: submitCareerPlanError,
  } = useSubmitCareerPlan(careerPlanId);

  const doSubmitCareerPlan = async () => {
    await submitCareerPlan();
    onSubmit?.();
  };

  const submitOnBlur = async () => {
    if (customGoal) {
      await submitGoal(customGoal);
    }
  };

  const submitButtonProps = useMemo(() => {
    const requirementsMet =
      draftGoals.short?.length > 0 && draftGoals.long?.length > 0;
    let enabled = requirementsMet;

    if (requirementsMet && careerPlanSubmittedAt) {
      // The plan was previously submitted and the requirements are still met.

      // Has the number of goals changed?
      const numGoalsTheSame =
        draftGoals.short.length === goals.short.length &&
        draftGoals.long.length === goals.long.length &&
        draftGoals.custom?.length === goals.custom?.length;

      if (numGoalsTheSame) {
        // Were any of the draft goals created after the submission date?
        const parsedCareerPlanSubmittedAt = Date.parse(careerPlanSubmittedAt);
        enabled = Object.values(draftGoals).some((_draftGoals) =>
          _draftGoals.some(
            (draftGoal) =>
              Date.parse(draftGoal.createdAt) > parsedCareerPlanSubmittedAt,
          ),
        );
      }
    }

    let label = "Send to Counselor";
    if (enabled) {
      label = careerPlanSubmittedAt
        ? "Resend to Counselor"
        : "Send to Counselor";
    } else if (requirementsMet) {
      label = "Submitted";
    }

    return { enabled, label };
  }, [draftGoals, careerPlanSubmittedAt]);

  const onGoalSelected = async (type, goal, orderNum) => {
    const existingGoal = draftGoals[type]?.find(
      (careerPlanGoal) => careerPlanGoal.orderNum === orderNum,
    );
    if (!existingGoal || !goal || existingGoal.careerGoalId !== goal.id) {
      await submitGoal({
        type,
        id: existingGoal?.id,
        careerPlanId,
        careerGoalId: goal?.id,
        orderNum,
      });
    }
  };

  const onCustomGoalChanged = (goalText, orderNum) => {
    if (goalText !== "" || orderNum in draftGoals.custom) {
      setCustomGoal({
        type: "custom",
        id: draftGoals.custom?.[orderNum].id,
        careerPlanId,
        goalText,
        orderNum,
      });
    }
  };

  const closeConfirmationModal = () => setOpenConfirmationModal();

  const onConfirmStartOver = async () => {
    await startOver();
    closeConfirmationModal();
    onRemovePlan?.();
  };

  const getLoaderMessage = () => {
    if (submittingCareerPlan) {
      return "submiting goals...";
    } else if (submittingGoal) {
      return "saving goal...";
    } else if (isStartingOver) {
      return "removing pathway and goals...";
    }
  };

  return (
    <>
      <Card className="goals-card">
        <CardHeader
          className="goals-card-header"
          title={<h3>My Goals for...</h3>}
          toolbar={
            <Button
              onClick={onClose}
              icon={["fa-regular", "xmark"]}
              title="close"
              {...buttonTracking("goals", "close")}
            />
          }
        >
          <h1>{career?.title}</h1>
          <h4>Select at least one short-term and long-term goal</h4>
        </CardHeader>
        <CardBody className="goals-card-body">
          <ErrorToast open={submitCareerPlanError || isGoalsByTypeError} />
          <GoalsContainer
            type="short"
            goals={goalsByType?.short}
            careerPlanGoals={draftGoals?.short}
            onGoalSelected={onGoalSelected}
          />
          <GoalsContainer
            type="long"
            goals={goalsByType?.long}
            careerPlanGoals={draftGoals?.long}
            onGoalSelected={onGoalSelected}
          />
          <CustomGoalsContainer
            selectedGoals={draftGoals?.custom}
            onBlur={submitOnBlur}
            onGoalTextChanged={onCustomGoalChanged}
          />
        </CardBody>
        <CardFooter className="goals-card-footer">
          <div className="actions-container">
            <Button
              primary
              icon={["fa-regular", "paper-plane"]}
              onClick={doSubmitCareerPlan}
              disabled={!submitButtonProps.enabled}
              {...buttonTracking("goals", "submit")}
            >
              {submitButtonProps.label}
            </Button>
            <Button
              icon={["fa-regular", "arrow-rotate-left"]}
              onClick={() => setOpenConfirmationModal(true)}
              {...buttonTracking("goals", "start-over")}
            >
              Start Over
            </Button>
          </div>
        </CardFooter>
      </Card>
      <Loader
        show={
          submittingCareerPlan || submittingGoal || isStartingOver || isFetching
        }
        text={getLoaderMessage()}
      />
      <GoalsStartOverConfirmationModal
        open={openConfirmationModal}
        onConfirm={onConfirmStartOver}
        onCancel={closeConfirmationModal}
        onClose={closeConfirmationModal}
        careerTitle={career?.title}
      />
    </>
  );
};

Goals.displayName = "Goals Card";

Goals.propTypes = {
  onClose: PropTypes.func,
  onRemovePlan: PropTypes.func,
  onSubmit: PropTypes.func,
};
