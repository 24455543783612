/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { forwardRef, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { SkeletonUtils } from "three-stdlib";

export const TumbleWeedModel = forwardRef((props, ref) => {
  const groupRef = useRef();
  const { scene } = useGLTF("/models/tumble-weed.glb");
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);

  return (
    <group ref={mergeRefs([groupRef, ref])} {...props}>
      <group name="Scene">
        <group name="tumble_weed_RIG">
          <primitive object={nodes.root} />
        </group>
        <skinnedMesh
          castShadow
          name="GEO_tumble_weed"
          geometry={nodes.GEO_tumble_weed.geometry}
          material={materials.MAT_tumble_weed}
          skeleton={nodes.GEO_tumble_weed.skeleton}
        />
      </group>
    </group>
  );
});

useGLTF.preload("/models/tumble-weed.glb");

TumbleWeedModel.displayName = "TumbleWeed Model";
