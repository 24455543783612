import { AnimatePresence } from "framer-motion";
import {
  startTransition,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { STEP_TO_MAP } from "@context/journey.context";

import { Loader } from "@features/ui";
import { FeedbackButton } from "./components";
import { JourneyViewToggle } from "./components/journey-view.toggle";
import { CareerMatchesSheet, JourneyMapShortcutsView } from "./views";
import { GoalsSheet } from "./views/goals.sheet";
import { HighSchoolSheet } from "./views/high-school.sheet";
import { InterestInventorySheet } from "./views/interest-inventory.sheet";
import { JourneyMap } from "./views/journey-map";
import { JourneyMapLoading } from "./views/journey-map.loading";
import { PostHighSchoolSheet } from "./views/post-high-school.sheet";
import { ShareSheet } from "./views/share.sheet";
import { SkillsSheet } from "./views/skills.sheet";

import { useDeleteCareerMatches } from "@features/career-matches/api/use-delete-career-matches";
import { useCreateUserJourney } from "./api/use-create-user-journey";
import { useJourneySteps } from "./api/use-journey-steps";
import { usePrefetchPathway } from "./api/use-prefetch-pathway";
import { useUserJourney } from "./api/use-user-journey";
import { useJourney } from "./hooks/use-journey";

import "./index.scss";

export const JourneyMapPage = () => {
  const pageRef = useRef();

  const [triggerNextMap, setTriggerNextMap] = useState();
  const [triggerMapAnimation, setTriggerMapAnimation] = useState();
  const [showSheet, setShowSheet] = useState();
  const [pageHeight, setPageHeight] = useState(0);
  const [isPlanShared, setIsPlanShared] = useState(false);
  const [pendingStep, setPendingStep] = useState();

  useLayoutEffect(() => {
    if (pageRef.current) {
      setPageHeight(pageRef.current.offsetHeight);
    }
  }, [pageRef.current]);

  const {
    currentMap,
    isJourneyLoading,
    requestStepChange,
    requestMapChange,
    unlockedSteps,
    nextMapUnlocked,
    journeyMapView,
    setJourneyMapView,
  } = useJourney();
  const { data: journeySteps } = useJourneySteps();
  const { data: userJourney, isPending, isError } = useUserJourney();
  const {
    isPending: createUserJourneyPending,
    isError: createUserJourneyError,
    mutateAsync: createUserJourney,
  } = useCreateUserJourney();
  const { mutateAsync: deleteCareerMatches, isPending: deletingCareerMatches } =
    useDeleteCareerMatches();

  usePrefetchPathway(userJourney.currentJourneyCareer?.id);

  useEffect(() => {
    if (!isPending && userJourney.status === 404) {
      createUserJourney();
    }
  }, [isPending, userJourney.status]);

  useEffect(() => {
    if (nextMapUnlocked && triggerNextMap && !triggerMapAnimation) {
      requestMapChange(currentMap + 1);
      setTriggerNextMap(() => false);
    }
  }, [nextMapUnlocked, triggerNextMap, triggerMapAnimation]);

  useEffect(() => {
    if (
      userJourney.surveyCompletedAt &&
      !showSheet &&
      journeyMapView &&
      currentMap === 1
    ) {
      setTriggerMapAnimation(STEP_TO_MAP.INT_INV);
    }
  }, [userJourney, showSheet, currentMap]);

  useEffect(() => {
    if (isPlanShared) {
      const timeoutId = setTimeout(() => {
        setIsPlanShared(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isPlanShared]);

  useEffect(() => {
    if (pendingStep) {
      moveToStep(pendingStep).then((success) => {
        if (success) {
          setPendingStep();
        }
      });
    }
  }, [unlockedSteps]);

  useEffect(() => {
    const eventName = showSheet ? "sheetopened" : "sheetclosed";
    dispatchEvent(new CustomEvent(eventName));
  }, [showSheet]);

  const closeSheet = () => setShowSheet();

  const onMapAnimationComplete = () => setTriggerMapAnimation();

  const moveToStep = async (journeyStep) => {
    if (await requestStepChange(journeyStep)) {
      startTransition(() => {
        setShowSheet(journeyStep.code);
      });
      return true;
    }

    return false;
  };

  const moveToMap = async (destinationMap) => {
    startTransition(() => {
      if (destinationMap) {
        requestMapChange(destinationMap);
      } else {
        setTriggerNextMap(true);
      }
    });
  };

  const onStartOverFromCareerMatches = async () => {
    closeSheet();
    await moveToMap(STEP_TO_MAP.INT_INV);
    await moveToStep(journeySteps.stepsByCode.INT_INV);
    // Note: this is done here so that the career matches sheet will already be
    // closed and the career matches won't be immediately re-loaded.
    await deleteCareerMatches();
  };

  const onExploreMatches = async () => {
    if (journeyMapView) {
      closeAndMoveToMap();
    } else {
      closeSheet();
      await moveToMap(STEP_TO_MAP.MATCHES);
      await moveToStep(journeySteps.stepsByCode.MATCHES);
    }
  };

  const onGoalsSubmitted = async () => {
    closeSheet();
    if (!journeyMapView) {
      await moveToMap(STEP_TO_MAP.SHARE);
    }

    // Let the map know it should move Dash to the Share step, which will then
    // automatically open the Share dialog.
    setTriggerMapAnimation(journeySteps.stepsByCode.SHARE.stepNum);
  };

  const onShare = () => {
    setIsPlanShared(true);
  };

  // TODO: Set the error and loading screen
  if ((isError && userJourney.status !== 404) || createUserJourneyError) {
    return <div>Error</div>;
  } else if (isJourneyLoading || isPending || createUserJourneyPending) {
    return <JourneyMapLoading />;
  }

  const closeAndMoveToMap = (destinationMap) => {
    closeSheet();
    moveToMap(destinationMap);
  };

  const onToggleMapView = () => {
    setJourneyMapView(!journeyMapView);
  };

  const sheets = {
    [journeySteps.stepsByCode.INT_INV.code]: {
      sheet: InterestInventorySheet,
      props: {
        onExploreMatches,
      },
    },
    [journeySteps.stepsByCode.MATCHES.code]: {
      sheet: CareerMatchesSheet,
      props: {
        onCareerSelected: () =>
          // Move to the next map once the user journey is updated
          closeAndMoveToMap(),
        onStartOver: onStartOverFromCareerMatches,
      },
    },
    [journeySteps.stepsByCode.SKILLS.code]: { sheet: SkillsSheet },
    [journeySteps.stepsByCode.HIGH_SCH.code]: { sheet: HighSchoolSheet },
    [journeySteps.stepsByCode.POST_HIGH_SCH.code]: {
      sheet: PostHighSchoolSheet,
    },
    [journeySteps.stepsByCode.GOALS.code]: {
      sheet: GoalsSheet,
      props: {
        onRemovePlan: () => closeAndMoveToMap(STEP_TO_MAP.MATCHES),
        onSubmit: onGoalsSubmitted,
      },
    },
    [journeySteps.stepsByCode.SHARE.code]: {
      sheet: ShareSheet,
      props: {
        onShare,
      },
    },
  };

  return (
    <div className="journey-map-page" ref={pageRef}>
      <Loader show={deletingCareerMatches} text={"removing matches..."} />
      <Loader show={pendingStep} text={"please wait..."} />
      <FeedbackButton />
      <JourneyViewToggle onToggle={onToggleMapView} />
      <AnimatePresence mode="popLayout">
        {journeyMapView ? (
          <JourneyMap
            pageHeight={pageHeight}
            currentMap={currentMap}
            triggerMapAnimation={triggerMapAnimation}
            moveToMap={moveToMap}
            moveToStep={moveToStep}
            onMapAnimationComplete={onMapAnimationComplete}
            unlockedSteps={unlockedSteps}
            isPlanShared={isPlanShared}
          />
        ) : (
          <JourneyMapShortcutsView
            journeySteps={journeySteps}
            moveToStep={moveToStep}
            unlockedSteps={unlockedSteps}
            moveToMap={moveToMap}
          />
        )}
      </AnimatePresence>
      {Object.entries(sheets).map(
        ([code, { sheet: SheetComponent, props = {} }]) => (
          <SheetComponent
            key={code}
            open={showSheet === code}
            onClose={closeSheet}
            {...props}
          />
        ),
      )}
    </div>
  );
};
