import { Billboard, useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";
import {
  AnimationAction,
  AnimationUtils,
  LoopOnce,
  LoopPingPong,
  Vector3,
} from "three";

import { DashModel } from "./dash.model";

import { JourneyPin } from "../components";
import { dashAnimations } from "../hooks";

export const talkingAnimation = {
  name: dashAnimations.Talking,
  options: {
    loop: { mode: LoopPingPong, action: dashAnimations.TalkingLoop },
  },
};

const outfitGeometries = Object.entries({
  winter: ["GEO_dash_hat", "GEO_jacket"],
}).reduce((acc, [outfit, geometries]) => {
  // The structure above is intended to make it easier to keep track of what
  // goes in each outfit, but here we're transforming it into a format that is
  // more efficient to use.
  //
  // This will give us a map like:
  // {
  //    'GEO_dash_hat': ['winter'],
  // }
  //
  // As we traverse the geometries when changing the outfit, we can simply ask:
  // is this geometry part of the desired outfit?
  for (const geometry of geometries) {
    const outfits = acc[geometry] ?? [];
    outfits.push(outfit);
    acc[geometry] = outfits;
  }

  return acc;
}, {});

const addOnceEventListener = (mixer, event, listener) => {
  const wrapper = (...args) => {
    mixer.removeEventListener(event, wrapper);
    listener(...args);
  };

  mixer.addEventListener(event, wrapper);
};

const stopAction = (action, timeout) => {
  setTimeout(() => {
    action.stop();
  }, timeout * 1000);
};

export const DashTheHedgehog = forwardRef(
  (
    {
      animation,
      outfit = "none",
      speech = { active: false },
      onActionFinished,
      ...props
    },
    ref,
  ) => {
    const { active: isSpeechActive = true } = speech;

    const groupRef = useRef();
    const lastAnimation = useRef();
    const lastAnimationOptions = useRef();

    const [rendered, setRendered] = useState(false);
    const [showSpeech, setShowSpeech] = useState(false);

    const { animations } = useGLTF("/models/dash-the-hedgehog.glb");
    const { actions } = useAnimations(animations, groupRef);

    const subactions = useMemo(() => {
      if (rendered) {
        // create mapping of custom animations
        const talkAction = actions[dashAnimations.Talking];
        return {
          [dashAnimations.TalkingLoop]: new AnimationAction(
            talkAction.getMixer(),
            AnimationUtils.subclip(
              talkAction.getClip(),
              dashAnimations.TalkingLoop,
              // the part we want to loop starts at frame 40 and continues to
              // the end of the clip
              40,
            ),
            talkAction.getRoot(),
          ),
        };
      }
    }, [rendered]);

    useEffect(() => {
      setRendered(true);
    }, []);

    useEffect(() => {
      groupRef.current.traverse((child) => {
        const outfits = outfitGeometries[child.name] ?? [outfit];
        child.visible = outfits.includes(outfit);
      });
    }, [outfit]);

    useEffect(() => {
      // make entire model cast/receive shadows
      groupRef.current.traverse((child) => {
        child.castShadow = props.castShadow ?? true;
        child.receiveShadow = props.receiveShadow ?? true;
      });
    }, [props.castShadow, props.receiveShadow]);

    useEffect(() => {
      if (isSpeechActive) {
        const { animationDuration, text, textDuration } = speech;
        const timeoutIds = [];

        setShowSpeech(!!text);

        if (animationDuration) {
          timeoutIds.push(
            setTimeout(() => {
              if (animation) {
                playAnimation(animation);
              } else {
                lastAnimation.current.stop();
                lastAnimation.current = undefined;
                lastAnimationOptions.current = undefined;
              }
            }, animationDuration),
          );
        }

        if (textDuration) {
          timeoutIds.push(
            setTimeout(() => {
              setShowSpeech(false);
            }, textDuration),
          );
        }

        return () => {
          for (const timeoutId of timeoutIds) {
            clearTimeout(timeoutId);
          }
        };
      } else if (lastAnimation.current) {
        const lastClipName = lastAnimation.current.getClip().name;
        if (lastClipName !== animation.name) {
          playAnimation(animation);
        }
      }
    }, [speech]);

    const playAnimation = (animation) => {
      const { name, options = {} } = animation;

      const animationAction = actions[name];
      const mixer = animationAction.getMixer();

      if (lastAnimation.current) {
        animationAction
          .reset()
          .crossFadeFrom(
            lastAnimation.current,
            options.transitionDuration ?? 0.5,
          );

        if (lastAnimationOptions.current?.loop) {
          // If it loops, stop the old animation after the transition is
          // complete so it won't keep looping.
          stopAction(lastAnimation.current, options.transitionDuration ?? 0.5);
        }
      }
      // prevent resetting to T-pose
      animationAction.clampWhenFinished = true;
      animationAction.play();
      lastAnimation.current = animationAction;
      lastAnimationOptions.current = options;

      if (options.once) {
        animationAction.setLoop(LoopOnce, 1);
        addOnceEventListener(mixer, "finished", () => {
          onActionFinished?.();
        });
      }

      if (options.duration) {
        animationAction.setDuration(options.duration);
      }

      if (options.loop) {
        const {
          mode,
          repetitions,
          action: loopActionName,
          transitionDuration,
        } = options.loop;

        if (loopActionName) {
          // do the first action a single time
          animationAction.setLoop(LoopOnce, 1);

          addOnceEventListener(mixer, "finished", () => {
            // when finished, fade in the looping action
            const loopAction =
              actions[loopActionName] ?? subactions[loopActionName];
            loopAction.setLoop(mode);
            loopAction
              .reset()
              .crossFadeFrom(lastAnimation.current, transitionDuration ?? 0.5)
              .play();

            stopAction(
              lastAnimation.current,
              options.transitionDuration ?? 0.5,
            );

            lastAnimation.current = loopAction;
          });
        } else {
          animationAction.setLoop(mode, repetitions ?? 1);
        }
      }
    };

    const onClickAnimation = ({ stopPropagation }) => {
      stopPropagation();
      playAnimation({ name: dashAnimations.Talking, options: { once: true } });
    };

    useLayoutEffect(() => {
      if (animation) {
        playAnimation(animation);
      }
    }, [animation.name, animation.options]);

    useLayoutEffect(() => {
      if (subactions && isSpeechActive) {
        playAnimation(talkingAnimation);
      }
    }, [subactions, speech]);

    return (
      <>
        <DashModel
          ref={mergeRefs([groupRef, ref])}
          onClick={onClickAnimation}
          {...props}
        />
        {isSpeechActive && showSpeech && (
          <Billboard
            position={props.position.clone().setY(props.position.y + 90)}
          >
            <JourneyPin
              size={[200, 50]}
              padding={10}
              text={speech.text}
              fontSize={12}
              color="#1d252c"
              fontColor="white"
              {...speech.pinConfig}
            />
          </Billboard>
        )}
      </>
    );
  },
);

DashTheHedgehog.displayName = "Dash";

DashTheHedgehog.propTypes = {
  animation: PropTypes.object,
  castShadow: PropTypes.bool,
  outfit: PropTypes.oneOf(["none", "winter"]),
  position: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Vector3),
  ]),
  speech: PropTypes.shape({
    active: PropTypes.bool,
    animationDuration: PropTypes.number,
    text: PropTypes.string,
    textDuration: PropTypes.number,
    pinConfig: PropTypes.object,
  }),
  receiveShadow: PropTypes.bool,
  onActionFinished: PropTypes.func,
};
