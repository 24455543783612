import PropTypes from "prop-types";

import { InterestTags } from "@features/career-matches/components";
import { Question, QuestionLoader } from "@features/ui";

import { CareerRating } from "../api/use-set-user-career-rating";

import "./rate-career.scss";

export const RateCareer = ({ career, isLoading, onRate }) => {
  return (
    <div className="rate-career">
      {isLoading ? (
        <QuestionLoader />
      ) : (
        <>
          <h3 className="header">
            <span>{career?.title}</span>
          </h3>
          <Question
            imageUrl={`/images/careers/${career?.transfrId}/questions/1.png`}
            text={career?.shortDescription}
            onAnswer={(answer) =>
              onRate({
                careerId: career.id,
                rating: answer ? CareerRating.one : CareerRating.zero,
              })
            }
          >
            <InterestTags tags={career?.tags} />
          </Question>
        </>
      )}
    </div>
  );
};

RateCareer.propTypes = {
  career: PropTypes.object,
  isLoading: PropTypes.bool,
  onRate: PropTypes.func,
};
