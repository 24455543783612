import { useQueries } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useRef, useState } from "react";

import { useCareerPlanQueryOptions } from "@features/career-details/api/use-career-plan";
import { useJourneyStepsQueryOptions } from "@pages/journey-map/api/use-journey-steps";
import { useUpdateUserJourney } from "@pages/journey-map/api/use-update-user-journey";
import { useUserJourneyQueryOptions } from "@pages/journey-map/api/use-user-journey";

export const JourneyContext = createContext();

export const STEP_TO_MAP = {
  BEGIN: 1,
  INT_INV: 1,
  MATCHES: 2,
  HIGH_SCH: 3,
  SKILLS: 3,
  POST_HIGH_SCH: 3,
  GOALS: 4,
  SHARE: 4,
  DONE: 4,
};

const pass = () => true;

const hasData =
  (key, next = pass) =>
  (data) => {
    const keys = key.split(".");

    let obj = data;
    for (const key of keys) {
      obj = obj[key];
      if (!obj) {
        return false;
      }
    }

    return next(data);
  };

const customValidator =
  (validator, next = pass) =>
  (data) => {
    if (!validator(data)) {
      return false;
    }

    return next(data);
  };

const MAP_UNLOCK_REQUIREMENTS = [
  // Map 0 -- this map doesn't exist
  pass,
  // Map 1
  pass,
  // Map 2
  hasData("userJourney.surveyCompletedAt"),
  // Map 3
  hasData("userJourney.currentJourneyCareer"),
  // Map 4
  pass,
  // Loop back
  pass,
];

const STEP_UNLOCK_REQUIREMENTS = {
  MATCHES: hasData("userJourney.surveyCompletedAt"),
  HIGH_SCH: hasData("userJourney.currentJourneyCareer"),
  SKILLS: hasData("userJourney.currentJourneyCareer"),
  POST_HIGH_SCH: hasData("userJourney.currentJourneyCareer"),
  GOALS: hasData("userJourney.currentJourneyCareer"),
  SHARE: hasData("careerPlan.submittedAt"),
};

const STEP_COMPLETED_REQUIREMENTS = {
  INT_INV: hasData("userJourney.surveyCompletedAt"),
  MATCHES: hasData("userJourney.currentJourneyCareer"),
  GOALS: hasData(
    "careerPlan.submittedAt",
    customValidator(
      (data) =>
        data.userJourney.currentJourneyCareer?.id === data.careerPlan?.careerId,
    ),
  ),
  SHARE: hasData("careerPlan.sharedAt"),
};

export const JourneyProvider = ({ children }) => {
  const userJourneyLoaded = useRef(false);

  const [journeyMapView, setJourneyMapView] = useState(true);
  const [currentMap, setCurrentMap] = useState();
  const [unlockedMaps, setUnlockedMaps] = useState([]);
  const [unlockedSteps, setUnlockedSteps] = useState([]);

  const {
    isPending: updateUserJourneyPending,
    isError: updateUserJourneyError,
    mutateAsync: updateUserJourney,
  } = useUpdateUserJourney();

  const results = useQueries({
    queries: [
      useJourneyStepsQueryOptions(),
      useUserJourneyQueryOptions(),
      useCareerPlanQueryOptions(),
    ],
  });
  const [journeySteps, userJourney, careerPlan] = results.map((r) => r.data);
  const isFetched = results.reduce((acc, r) => acc && r.isFetched, true);

  const requestMapChange = useCallback(
    (requestedMap) => {
      const firstLockedMap = unlockedMaps.findIndex((unlocked) => !unlocked);

      let newMap = requestedMap;
      if (firstLockedMap !== -1) {
        newMap = Math.min(firstLockedMap - 1, newMap);
      }
      setCurrentMap(newMap);
    },
    [unlockedMaps],
  );

  const requestStepChange = useCallback(
    async (journeyStep) => {
      const stepChanged =
        journeyStep.stepNum !== userJourney.currentJourneyStep.stepNum;

      if (stepChanged && unlockedSteps[journeyStep.stepNum]) {
        await updateUserJourney({
          id: userJourney.id,
          stepNum: journeyStep.stepNum,
        });

        return true;
      }

      return !stepChanged;
    },
    [unlockedSteps, userJourney],
  );

  useEffect(() => {
    // Don't bother updating unlocked maps until all data has loaded.
    if (isFetched) {
      const unlockData = {
        journeySteps,
        userJourney,
        careerPlan,
      };
      const unlockedMaps = MAP_UNLOCK_REQUIREMENTS.map((isUnlocked) =>
        isUnlocked(unlockData),
      );
      setUnlockedMaps(unlockedMaps);

      const unlockedSteps = journeySteps.steps.map(
        (step) =>
          !Object.hasOwn(STEP_UNLOCK_REQUIREMENTS, step.code) ||
          STEP_UNLOCK_REQUIREMENTS[step.code](unlockData),
      );
      setUnlockedSteps(unlockedSteps);
    }
  }, [isFetched, journeySteps, userJourney, careerPlan]);

  useEffect(() => {
    // Find the map that the current journey step lives on.
    if (unlockedMaps.length > 0 && !userJourneyLoaded.current) {
      userJourneyLoaded.current = true;
      const stepMap = STEP_TO_MAP[userJourney.currentJourneyStep.code];
      requestMapChange(stepMap);
    }
  }, [unlockedMaps, userJourney.currentJourneyStep.code]);

  return (
    <JourneyContext.Provider
      value={{
        currentMap,
        journeyMapView,
        setJourneyMapView,
        isJourneyLoading: !isFetched,
        nextMapUnlocked: unlockedMaps[currentMap + 1],
        unlockedSteps,
        requestMapChange,
        requestStepChange,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
};

JourneyProvider.propTypes = {
  children: PropTypes.node,
};
