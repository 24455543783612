/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

export const Blueberries = ({ hoverAnimation = {}, onClick, ...props }) => {
  const { nodes, materials } = useGLTF("/models/blueberries.glb");

  return (
    <group {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onClick={onClick}
      >
        <instancedMesh
          args={[nodes.Circle008.geometry, materials.MAT_leaf, 19]}
          castShadow
          receiveShadow
          instanceMatrix={nodes.Circle008.instanceMatrix}
        />
        <instancedMesh
          args={[nodes.Circle008_1.geometry, materials.blue_berry, 19]}
          castShadow
          receiveShadow
          instanceMatrix={nodes.Circle008_1.instanceMatrix}
        />
        <instancedMesh
          args={[nodes.Circle008_2.geometry, materials.blueBerry_stem_dot, 19]}
          castShadow
          receiveShadow
          instanceMatrix={nodes.Circle008_2.instanceMatrix}
        />
        <instancedMesh
          args={[nodes.Circle008_3.geometry, materials["blue_berry-dark"], 19]}
          castShadow
          receiveShadow
          instanceMatrix={nodes.Circle008_3.instanceMatrix}
        />
      </motion.group>
    </group>
  );
};

useGLTF.preload("/models/blueberries.glb");

Blueberries.propTypes = {
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
