import { useEffect, useMemo, useRef, useState } from "react";
import { LoopPingPong } from "three";

import { useLocalStorage } from "@hooks/use-local-storage";
import { dashAnimations } from "@pages/journey-map/hooks/use-dash-hedgehog";

export const introAnimation = {
  name: dashAnimations.Talking,
  options: {
    loop: { mode: LoopPingPong, action: dashAnimations.TalkingLoop },
  },
};

export const useIntro = ({
  animationDuration = 5000,
  initialPosition,
  introInitialPosition,
  introKey,
  introPathPoints,
  mainPathPoints,
  pinConfig,
  text,
  textDuration = 10_000,
}) => {
  const dash = useRef();

  const [dashSpeed, setDashSpeed] = useState(0.5);
  const [speech, setSpeech] = useState();

  const [viewedIntros, setViewedIntros] = useLocalStorage("viewedIntros");
  const viewedIntro = useMemo(
    () => viewedIntros?.[introKey],
    [introKey, viewedIntros],
  );

  const dashProps = {
    initialPosition,
    pathPoints: mainPathPoints,
    speed: dashSpeed,
    setDash: (d) => (dash.current = d),
  };
  if (!viewedIntro) {
    dashProps.initialPosition = introInitialPosition ?? initialPosition;
    dashProps.pathPoints = introPathPoints ?? mainPathPoints;
  }

  useEffect(() => {
    if (!viewedIntro) {
      setSpeech({
        active: true,
        text,
        animationDuration,
        textDuration,
        pinConfig,
      });

      const timeoutId = setTimeout(closeIntro, textDuration);

      return () => clearTimeout(timeoutId);
    }
  }, [viewedIntro]);

  const closeIntro = () => {
    setSpeech();

    if (!viewedIntro) {
      if (introPathPoints) {
        setDashSpeed(1.5);
        dash.current.setDestination({
          distance: 1,
          onDestinationReached: () => {
            setDashSpeed(0.5);
            setViewedIntros({ ...viewedIntros, [introKey]: true });
          },
        });
      } else {
        setViewedIntros({ ...viewedIntros, [introKey]: true });
      }
    }
  };

  return {
    closeIntro,
    dashProps,
    speech,
    viewedIntro,
  };
};
