import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { createPortal } from "react-dom";

import { useBreakpoints } from "@hooks/use-breakpoints";

import "./modal.scss";

export const Modal = ({
  backgroundClassName,
  children,
  desktopAttachSelector = ".outlet",
  mobileAttachSelector = "body",
  modalClassName,
  onCloseCompleted,
  open,
}) => {
  const { isMobile } = useBreakpoints();

  const portalContainerElement = useMemo(() => {
    const selector = isMobile ? mobileAttachSelector : desktopAttachSelector;
    return document.querySelector(selector);
  }, [isMobile]);

  return createPortal(
    <AnimatePresence mode="popLayout" onExitComplete={onCloseCompleted}>
      {open && (
        <motion.div
          key="tx-modal-background"
          className={clsx("tx-modal-background", backgroundClassName)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            key="tx-modal-component"
            initial={{ y: "100vh", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100vh", opacity: 0 }}
            className={clsx("tx-modal", modalClassName)}
            transition={{
              type: "spring",
              mass: 0.5,
              stiffness: 90,
            }}
          >
            <FocusTrap>{children}</FocusTrap>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    portalContainerElement,
    "tx-modal-component",
  );
};

Modal.displayName = "Modal";

Modal.propTypes = {
  backgroundClassName: PropTypes.string,
  children: PropTypes.node,
  desktopAttachSelector: PropTypes.string,
  mobileAttachSelector: PropTypes.string,
  modalClassName: PropTypes.string,
  onCloseCompleted: PropTypes.func,
  open: PropTypes.bool,
};
