import { useA11y } from "@react-three/a11y";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";
import { useEffect } from "react";

export const JourneyButton3D = ({
  hoverAnimation = {},
  enabled = true,
  position,
  rotation,
  onClick,
  children,
  ...rest
}) => {
  const { focus, hover } = useA11y();

  useEffect(() => {
    if (focus || hover) {
      hoverAnimation.onPointerOver?.();
    } else {
      hoverAnimation.onPointerOut?.();
    }
  }, [focus, hover]);

  return (
    <motion.group
      onPointerOver={hoverAnimation.onPointerOver}
      onPointerOut={hoverAnimation.onPointerOut}
      onClick={(e) => enabled && onClick?.(e)}
      position={position}
      rotation={rotation}
      animate={hoverAnimation.animate}
      variants={hoverAnimation.variants}
      {...rest}
    >
      {children}
    </motion.group>
  );
};

JourneyButton3D.propTypes = {
  a11y: PropTypes.bool,
  children: PropTypes.node,
  enabled: PropTypes.bool,
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
  position: PropTypes.array,
  rotation: PropTypes.array,
};
