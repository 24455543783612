import PropTypes from "prop-types";

import {
  Badge,
  ErrorToast,
  InfoIcon,
  Section,
  Toast,
  ToastType,
} from "@features/ui";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { MetadataItem } from "./metadata.item";

import { buttonTracking } from "@lib/tracking.helpers";
import { useRequestResetPasswordToken } from "@pages/forgot-password/api/use-send-reset-password-email";

import { ROLE_NAMES, ROLES } from "src/constants";

import "./metadata.section.scss";

export const MetadataSection = ({ user }) => {
  const {
    mutateAsync: sendResetPasswordEmail,
    isPending: isPasswordResetPending,
    isSuccess: isPasswordResetSuccess,
    error: passwordResetError,
  } = useRequestResetPasswordToken();

  const {
    claims: { act: role, preferred_username: username },
    email,
    msa,
  } = user;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    await sendResetPasswordEmail(email);
  };

  return (
    <Section className="metadata-section">
      {username !== email && (
        <MetadataItem
          className="username-item"
          label="Username"
          value={username}
        />
      )}
      {email && (
        <MetadataItem className="email-item" label="Email" value={email} />
      )}
      <MetadataItem
        className="user-type-item"
        label="User Type"
        value={<Badge>{ROLE_NAMES[role]}</Badge>}
      >
        {role !== ROLES.student && (
          <div className="metadata-info">
            Your data will not be saved
            <InfoIcon
              variant="fa-solid"
              description="Data is only saved for Student accounts."
            />
          </div>
        )}
      </MetadataItem>
      {email && (
        <MetadataItem
          className="password-item"
          label="Password"
          value={
            isPasswordResetSuccess ? (
              <Toast
                open
                type={ToastType.success}
                message="Password reset email has been sent!"
              />
            ) : (
              <Button
                className="reset-password"
                icon={!isPasswordResetPending && ["fa-regular", "lock-keyhole"]}
                onClick={handleResetPassword}
                disabled={isPasswordResetPending}
                loader={isPasswordResetPending}
                {...buttonTracking("profile", "reset-password")}
              >
                Reset Password
              </Button>
            )
          }
        >
          <div className="metadata-info">
            <ErrorToast
              open={!!passwordResetError}
              message="Something went wrong. Please try again."
            />
          </div>
        </MetadataItem>
      )}
      <MetadataItem
        label="Location"
        description="We'll use your organization's location to show you career data near you."
        value={msa?.name ?? "Not available"}
      />
    </Section>
  );
};

MetadataSection.displayName = "Metadata Section";

MetadataSection.propTypes = {
  user: PropTypes.object,
};
