import { useEffect, useState } from "react";

const formatTime = (seconds) => {
  const sec = seconds % 60;
  const minutes = Number.parseInt(seconds / 60);
  return `${minutes}:${sec > 9 ? "" : "0"}${sec}`.trim();
};

export const useTimer = () => {
  const [timeLeft, setTimeLeft] = useState();
  const [isDone, setIsDone] = useState(false);
  const [timeFormatted, setTimeFormatted] = useState();
  const [pause, setPause] = useState();

  useEffect(() => {
    if (!pause) {
      const intervalId = setInterval(() => {
        setTimeLeft((t) => (t > 0 ? t - 1 : t));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [pause]);

  useEffect(() => {
    setIsDone(timeLeft <= 0);
    setTimeFormatted(formatTime(timeLeft ?? 0));
  }, [timeLeft]);

  const setTimer = (time) => {
    setTimeLeft(Math.trunc(time));
    setPause();
  };

  return {
    setTimer,
    timeLeft,
    isDone,
    timeFormatted,
    pause: () => setPause(true),
  };
};
