/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { forwardRef, useMemo, useRef } from "react";
import { Box3, Vector3 } from "three";

import { getRootMotionInterpolant } from "@lib/model.helpers";
import { useAnimationSequence } from "@pages/journey-map/hooks/use-animation-sequence";
import { useFollowPath } from "@pages/journey-map/hooks/use-follow-path";
import { mergeRefs } from "react-merge-refs";
import { TumbleWeedModel } from "./tumble-weed.model";

const upperBounds = new Box3(
  new Vector3(Number.NaN, 0, -250),
  new Vector3(Number.NaN, 0, 0),
);

const lowerBounds = new Box3(
  new Vector3(Number.NaN, 0, 150),
  new Vector3(Number.NaN, 0, 350),
);

const animationSequence = [
  {
    name: "ANIM_tumble1",
    crossFadeDuration: 0.25,
  },
  {
    name: "ANIM_tumble2",
    crossFadeDuration: 0.25,
  },
  {
    name: "ANIM_tumble3",
    crossFadeDuration: 0.25,
  },
];

export const RandomTumbleWeed = forwardRef((props, ref) => {
  const groupRef = useRef();

  const { animations } = useGLTF("/models/tumble-weed.glb");
  const { actions } = useAnimations(animations, groupRef);

  const animationInterpolants = useMemo(() => {
    const mapping = {};
    if (groupRef.current) {
      for (const action of Object.values(actions)) {
        const clip = action.getClip();
        mapping[clip.name] = getRootMotionInterpolant(groupRef.current, clip);
      }
    }
    return mapping;
  }, [actions, groupRef.current]);

  const animation = useAnimationSequence(actions, animationSequence);
  const { position } = useFollowPath({
    animation,
    appearanceWindow: [2500, 7500],
    bounds: Math.random() < 0.5 ? lowerBounds : upperBounds,
    // This model's z-axis is reversed
    directionVector: new Vector3(0, 0, -1),
    interpolant: animationInterpolants[animation?.getClip().name],
    model: groupRef.current,
  });

  return (
    <TumbleWeedModel
      ref={mergeRefs([groupRef, ref])}
      position={position}
      {...props}
    />
  );
});

RandomTumbleWeed.displayName = "Random TumbleWeed";
