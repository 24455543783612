/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { forwardRef, useMemo } from "react";
import { SkeletonUtils } from "three-stdlib";

export const BirdModel = forwardRef((props, ref) => {
  const { scene } = useGLTF("/models/bird.glb");
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);

  return (
    <group ref={ref} {...props}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes["MCH-torsoparent"]} />
        </group>
        <group name="GEO_bird">
          <skinnedMesh
            name="Cube"
            geometry={nodes.Cube.geometry}
            material={materials.MAT_bird}
            skeleton={nodes.Cube.skeleton}
          />
          <skinnedMesh
            name="Cube_1"
            geometry={nodes.Cube_1.geometry}
            material={materials.MAT_eye}
            skeleton={nodes.Cube_1.skeleton}
          />
        </group>
      </group>
    </group>
  );
});

useGLTF.preload("/models/bird.glb");

BirdModel.displayName = "Bird Model";
