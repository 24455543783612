import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

import { ButtonVariants } from "@lib/animation.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import { useJourney } from "../hooks/use-journey";

import { CareerPlanGuard, JourneyMapGuards } from "../guards";

import "./journey.header.scss";

const mapTitles = {
  1: "Find Your Pathway",
  2: "Start Pathway",
  3: "Set Up Goals",
};

export const JourneyMapHeader = forwardRef(function JourneyMapHeader(
  { inverted },
  ref,
) {
  const { currentMap, requestMapChange, nextMapUnlocked } = useJourney();

  const isJourneyCompleted = currentMap === 4;
  const [activateGuard, setActivateGuard] = useState();

  const MAP_GUARDS = {
    4: CareerPlanGuard,
  };

  const onNextClick = () => {
    const nextMap = currentMap + 1;
    setActivateGuard(`${nextMap}`);
  };

  const requestNextMap = () => {
    setActivateGuard();
    requestMapChange(currentMap + 1);
  };

  const deactivateGuard = () => setActivateGuard();

  return (
    <div
      className={clsx("journey-map-header", isJourneyCompleted && "completed")}
      ref={ref}
    >
      {!isJourneyCompleted && (
        <motion.button
          variants={ButtonVariants}
          custom={!nextMapUnlocked}
          whileHover="hover"
          whileTap="tap"
          className={clsx("next-map-button", { disabled: !nextMapUnlocked })}
          onClick={() => nextMapUnlocked && onNextClick()}
          disabled={!nextMapUnlocked}
          {...buttonTracking("journey-map", "next")}
          aria-label="Go next map"
        >
          <FontAwesomeIcon
            icon="fa-regular fa-arrow-up"
            rotation={inverted ? 180 : 0}
          />
        </motion.button>
      )}
      <span className="title">
        {nextMapUnlocked ? undefined : (
          <FontAwesomeIcon icon="fa-regular fa-lock" />
        )}
        {mapTitles[currentMap]}
      </span>
      <JourneyMapGuards
        guards={MAP_GUARDS}
        activeGuardKey={activateGuard}
        onContinue={requestNextMap}
        onReject={deactivateGuard}
      />
    </div>
  );
});

JourneyMapHeader.propTypes = {
  inverted: PropTypes.bool,
};
