import PropTypes from "prop-types";

import { Card, CardBody, CardHeader, ErrorToast } from "@features/ui";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { useSkills } from "@features/skills/api/use-skills";
import { buttonTracking } from "@lib/tracking.helpers";
import { SkillsContainer } from "./components";

import "./index.scss";

export const Skills = ({ careerId, onClose }) => {
  const {
    data: skills,
    isError: isSkillsError,
    isPending: isSkillsPending,
  } = useSkills(careerId);

  const skillsByType = skills?.reduce(
    (acc, skill) => {
      acc[skill["type"]].push(skill);
      return acc;
    },
    { general: [], specific: [] },
  );

  return (
    <Card className="skills-card">
      <CardHeader
        title={
          <h1>
            Skills{" "}
            <div>
              {isSkillsPending || isSkillsError ? "..." : skills.length}
            </div>
          </h1>
        }
        toolbar={
          <Button
            onClick={onClose}
            icon={["fa-regular", "xmark"]}
            title="close"
            {...buttonTracking("skills", "close")}
          />
        }
      >
        <div className="subheader">Specialized required job skills.</div>
      </CardHeader>
      <CardBody className="skills-card-body">
        {isSkillsError ? (
          <ErrorToast
            open
            message="Not currently available. Please try again later."
          />
        ) : (
          <>
            <SkillsContainer
              label="General"
              skills={skillsByType?.general}
              loading={isSkillsPending}
            />
            <SkillsContainer
              label="Specialized"
              skills={skillsByType?.specific}
              loading={isSkillsPending}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};

Skills.displayName = "Skills Card";

Skills.propTypes = {
  careerId: PropTypes.number,
  onClose: PropTypes.func,
};
