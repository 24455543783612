// Dimensions used for the prototype. This is used to provide ratios so things
// will lay out proportionally. The specific pixel values don't matter other

import { Vector3 } from "three";

// than to ensure we have the proper ratio.
export const PROTO_DIMENSIONS = { width: 393, height: 592 };
export const PROTO_ASPECT = PROTO_DIMENSIONS.width / PROTO_DIMENSIONS.height;

// Given x/y coordinates, return normalized values between -0.5 and 0.5, where
// [-0.5, -0.5] is the top-left corner, and [0.5, 0.5] is the bottom-right corner.
export const normalizePosition = (x, y, z = 0) => [
  x / PROTO_DIMENSIONS.width - 0.5,
  0.5 - y / PROTO_DIMENSIONS.height,
  z,
];

// Given dimensions, return normalized values between 0 and 1.
export const normalizeSize = (width, height, depth = 1) => [
  width / PROTO_DIMENSIONS.width,
  height / PROTO_DIMENSIONS.height,
  depth,
];

export const arrayToVector = (value) => {
  console.assert(Array.isArray(value) && value.length === 3, "%o", {
    value,
    error: "value is not and array vector-like",
  });
  return new Vector3(...value);
};
