/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { forwardRef, useMemo } from "react";
import { SkeletonUtils } from "three-stdlib";

export const DashModel = forwardRef((props, ref) => {
  const { scene } = useGLTF("/models/dash-the-hedgehog.glb");
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);

  return (
    <group ref={ref} {...props}>
      <group name="Scene">
        <group name="dash-RIG">
          <primitive object={nodes.root} />
          <primitive object={nodes["MCH-torsoparent"]} />
          <primitive object={nodes["MCH-hand_ikparentL"]} />
          <primitive object={nodes["MCH-upper_arm_ik_targetparentL"]} />
          <primitive object={nodes["MCH-hand_ikparentR"]} />
          <primitive object={nodes["MCH-upper_arm_ik_targetparentR"]} />
          <primitive object={nodes["MCH-foot_ikparentL"]} />
          <primitive object={nodes["MCH-thigh_ik_targetparentL"]} />
          <primitive object={nodes["MCH-foot_ikparentR"]} />
          <primitive object={nodes["MCH-thigh_ik_targetparentR"]} />
          <primitive object={nodes["MCH-lip_armBL001"]} />
          <primitive object={nodes["MCH-lip_armBR001"]} />
          <primitive object={nodes["MCH-lip_armTL001"]} />
          <primitive object={nodes["MCH-lip_armTR001"]} />
          <primitive object={nodes["MCH-nose_end_mix001"]} />
        </group>
        <group name="GEO_dash_botTeeth001">
          <skinnedMesh
            name="Cylinder003"
            geometry={nodes.Cylinder003.geometry}
            material={materials.MAT_botTeeth}
            skeleton={nodes.Cylinder003.skeleton}
          />
          <skinnedMesh
            name="Cylinder003_1"
            geometry={nodes.Cylinder003_1.geometry}
            material={materials.PaletteMaterial002}
            skeleton={nodes.Cylinder003_1.skeleton}
          />
        </group>
        <skinnedMesh
          name="GEO_dash_cheek_fringeL"
          geometry={nodes.GEO_dash_cheek_fringeL.geometry}
          material={materials["MAT_face_fringe-2"]}
          skeleton={nodes.GEO_dash_cheek_fringeL.skeleton}
        />
        <skinnedMesh
          name="GEO_dash_cheek_fringeR"
          geometry={nodes.GEO_dash_cheek_fringeR.geometry}
          material={materials["MAT_face_fringe-2"]}
          skeleton={nodes.GEO_dash_cheek_fringeR.skeleton}
        />
        <group name="GEO_dash_earL">
          <skinnedMesh
            name="Plane007"
            geometry={nodes.Plane007.geometry}
            material={materials.MAT_ear}
            skeleton={nodes.Plane007.skeleton}
          />
          <skinnedMesh
            name="Plane007_1"
            geometry={nodes.Plane007_1.geometry}
            material={materials["MAT_inner-ear"]}
            skeleton={nodes.Plane007_1.skeleton}
          />
        </group>
        <group name="GEO_dash_earR">
          <skinnedMesh
            name="Plane003"
            geometry={nodes.Plane003.geometry}
            material={materials.MAT_ear}
            skeleton={nodes.Plane003.skeleton}
          />
          <skinnedMesh
            name="Plane003_1"
            geometry={nodes.Plane003_1.geometry}
            material={materials["MAT_inner-ear"]}
            skeleton={nodes.Plane003_1.skeleton}
          />
        </group>
        <skinnedMesh
          name="GEO_dash_feetL"
          geometry={nodes.GEO_dash_feetL.geometry}
          material={materials.PaletteMaterial004}
          skeleton={nodes.GEO_dash_feetL.skeleton}
        />
        <skinnedMesh
          name="GEO_dash_feetR"
          geometry={nodes.GEO_dash_feetR.geometry}
          material={materials.PaletteMaterial004}
          skeleton={nodes.GEO_dash_feetR.skeleton}
        />
        <group name="GEO_dash_hat">
          <skinnedMesh
            name="Plane012"
            geometry={nodes.Plane012.geometry}
            material={materials.MAT_winter_hat}
            skeleton={nodes.Plane012.skeleton}
          />
          <skinnedMesh
            name="Plane012_1"
            geometry={nodes.Plane012_1.geometry}
            material={materials.PaletteMaterial005}
            skeleton={nodes.Plane012_1.skeleton}
          />
        </group>
        <skinnedMesh
          name="GEO_dash_tongue"
          geometry={nodes.GEO_dash_tongue.geometry}
          material={materials.PaletteMaterial006}
          skeleton={nodes.GEO_dash_tongue.skeleton}
        />
        <group name="GEO_dash_topTeeth001">
          <skinnedMesh
            name="Cylinder001"
            geometry={nodes.Cylinder001.geometry}
            material={materials.MAT_topTeeth}
            skeleton={nodes.Cylinder001.skeleton}
          />
          <skinnedMesh
            name="Cylinder001_1"
            geometry={nodes.Cylinder001_1.geometry}
            material={materials.PaletteMaterial002}
            skeleton={nodes.Cylinder001_1.skeleton}
          />
        </group>
        <group name="GEO_jacket">
          <skinnedMesh
            name="Plane005"
            geometry={nodes.Plane005.geometry}
            material={materials.MAT_jacket_collar}
            skeleton={nodes.Plane005.skeleton}
          />
          <skinnedMesh
            name="Plane005_1"
            geometry={nodes.Plane005_1.geometry}
            material={materials.MAT_jacket}
            skeleton={nodes.Plane005_1.skeleton}
          />
          <skinnedMesh
            name="Plane005_2"
            geometry={nodes.Plane005_2.geometry}
            material={materials.MAT_jacket_trim}
            skeleton={nodes.Plane005_2.skeleton}
          />
          <skinnedMesh
            name="Plane005_3"
            geometry={nodes.Plane005_3.geometry}
            material={materials.MAT_jacket_trim}
            skeleton={nodes.Plane005_3.skeleton}
          />
        </group>
        <group name="GEO_dash_body">
          <skinnedMesh
            name="Plane006"
            geometry={nodes.Plane006.geometry}
            material={materials.MAT_body2}
            skeleton={nodes.Plane006.skeleton}
            morphTargetDictionary={nodes.Plane006.morphTargetDictionary}
            morphTargetInfluences={nodes.Plane006.morphTargetInfluences}
          />
          <skinnedMesh
            name="Plane006_1"
            geometry={nodes.Plane006_1.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.Plane006_1.skeleton}
            morphTargetDictionary={nodes.Plane006_1.morphTargetDictionary}
            morphTargetInfluences={nodes.Plane006_1.morphTargetInfluences}
          />
        </group>
        <skinnedMesh
          name="GEO_dash_eyeL"
          geometry={nodes.GEO_dash_eyeL.geometry}
          material={materials.PaletteMaterial003}
          skeleton={nodes.GEO_dash_eyeL.skeleton}
          morphTargetDictionary={nodes.GEO_dash_eyeL.morphTargetDictionary}
          morphTargetInfluences={nodes.GEO_dash_eyeL.morphTargetInfluences}
        />
        <skinnedMesh
          name="GEO_dash_eyeR"
          geometry={nodes.GEO_dash_eyeR.geometry}
          material={materials.PaletteMaterial003}
          skeleton={nodes.GEO_dash_eyeR.skeleton}
          morphTargetDictionary={nodes.GEO_dash_eyeR.morphTargetDictionary}
          morphTargetInfluences={nodes.GEO_dash_eyeR.morphTargetInfluences}
        />
        <skinnedMesh
          name="GEO_dash_face_fringe"
          geometry={nodes.GEO_dash_face_fringe.geometry}
          material={materials.MAT_face_fringe}
          skeleton={nodes.GEO_dash_face_fringe.skeleton}
          morphTargetDictionary={
            nodes.GEO_dash_face_fringe.morphTargetDictionary
          }
          morphTargetInfluences={
            nodes.GEO_dash_face_fringe.morphTargetInfluences
          }
        />
        <skinnedMesh
          name="GEO_dash_quills"
          geometry={nodes.GEO_dash_quills.geometry}
          material={materials["MAT_underQuills.001"]}
          skeleton={nodes.GEO_dash_quills.skeleton}
          morphTargetDictionary={nodes.GEO_dash_quills.morphTargetDictionary}
          morphTargetInfluences={nodes.GEO_dash_quills.morphTargetInfluences}
        />
      </group>
    </group>
  );
});

useGLTF.preload("/models/dash-the-hedgehog.glb");

DashModel.displayName = "Dash Model";
