/* eslint react/no-unknown-property: 0 */

import { Box } from "@react-three/drei";
import PropTypes from "prop-types";
import { forwardRef, useLayoutEffect } from "react";
import { Box3 } from "three";

// Obstacle component to trigger Dash jumping action
export const Obstacle = forwardRef(
  ({ dimensions, position, visible = false, children }, ref) => {
    useLayoutEffect(() => {
      // Set the Box3 object to the reference so it can be passed to the Dash's hook
      ref.current.userData = new Box3().setFromObject(ref.current, true);
    }, []);

    return (
      <Box ref={ref} args={dimensions} position={position} visible={visible}>
        {children}
      </Box>
    );
  },
);

Obstacle.displayName = "Obstacle Model";

Obstacle.propTypes = {
  dimensions: PropTypes.array,
  children: PropTypes.node,
  position: PropTypes.array,
  // Helpful when positioning the object
  visible: PropTypes.bool,
};
