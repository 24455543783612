/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const campfireAnimationName = "Animation";

export function Campfire({ animated, hoverAnimation = {}, ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/campfire.glb");
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    const animation = actions[campfireAnimationName];

    if (animated) {
      animation.play();
    } else {
      animation.stop();
    }
  }, [animated]);

  return (
    <group ref={group} {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        name="Scene"
      >
        <mesh
          name="GEO_camp_fire_log001"
          castShadow
          receiveShadow
          geometry={nodes.GEO_camp_fire_log001.geometry}
          material={materials.MAT_camp_fire_log}
          position={[0.019, 0.055, -0.055]}
          rotation={[-3.036, -0.992, -2.351]}
        />
        <mesh
          name="GEO_campfire_rock"
          castShadow
          receiveShadow
          geometry={nodes.GEO_campfire_rock.geometry}
          material={materials.MAT_stone3}
          position={[0.082, 0.012, -0.15]}
          rotation={[0, -0.712, 0]}
        />
        <mesh
          name="GEO_camp_fire_flame"
          castShadow
          receiveShadow
          geometry={nodes.GEO_camp_fire_flame.geometry}
          material={materials.MAT_camp_fire}
          position={[0.003, 0.043, 0.008]}
          scale={[1, 1.149, 1]}
        />
        <mesh
          name="GEO_camp_fire_flame001"
          castShadow
          receiveShadow
          geometry={nodes.GEO_camp_fire_flame001.geometry}
          material={materials.MAT_camp_fire}
          position={[0.052, 0.047, -0.027]}
          scale={[1, 0.989, 1]}
        />
        <mesh
          name="GEO_camp_fire_flame002"
          castShadow
          receiveShadow
          geometry={nodes.GEO_camp_fire_flame002.geometry}
          material={materials.MAT_camp_fire}
          position={[-0.002, 0.038, -0.042]}
          scale={[1, 1.308, 1]}
        />
      </motion.group>
    </group>
  );
}

useGLTF.preload("/models/campfire.glb");

Campfire.propTypes = {
  animated: PropTypes.bool,
  hoverAnimation: PropTypes.object,
};
