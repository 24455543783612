import { A11y } from "@react-three/a11y";
import PropTypes from "prop-types";

// The A11y component creates an invisible DOM element that floats above the 3D
// element it wraps. This DOM element exists for screen readers, but doesn't
// actually need to be interactive. However, since it is HTML layered on top of
// the canvas, it still takes pointer events even if you try to put something in
// front of the 3D element (like when using the Overlay component). This wrapper
// just makes it non-blocking by making the DOM element not take pointer events.
export const NonBlockingA11y = ({ active, ...props }) => (
  <A11y
    a11yElStyle={{ pointerEvents: "none" }}
    tabIndex={active ? 0 : -1}
    {...props}
  />
);

NonBlockingA11y.propTypes = {
  active: PropTypes.bool,
};
