import { useEffect } from "react";

import { useCareerPlan } from "@features/career-details/api/use-career-plan";
import { useCreateCareerPlanGoal } from "@features/goals/api/use-create-career-plan-goal";
import { useAuth } from "@hooks/use-auth";
import { useLocalStorage } from "@hooks/use-local-storage";

export const useSyncState = (key) => {
  const { user } = useAuth();
  const [syncState, setSyncState] = useLocalStorage(`${user.userId}.syncState`);

  const setValue = (newValue) => {
    if (newValue === undefined) {
      const { [key]: ignore, ...newSyncState } = syncState;
      setSyncState(newSyncState);
    } else {
      setSyncState({
        ...syncState,
        [key]: newValue,
      });
    }
  };

  return [syncState?.[key], setValue];
};

export const useDoSync = () => {
  const onCareerPlanGoalCreated = () => {
    setCustomGoal();
  };

  const [customGoal, setCustomGoal] = useSyncState("customGoal");
  const { isFetched: careerPlanFetched, data: careerPlan } = useCareerPlan();
  const { mutateAsync: submitGoal } = useCreateCareerPlanGoal(
    careerPlan.id,
    onCareerPlanGoalCreated,
  );

  // This should only run one time, as soon as all required data is loaded. This
  // is intended to sync any pending state to the server as soon as possible.
  useEffect(() => {
    if (careerPlanFetched && customGoal) {
      submitGoal(customGoal);
    }
  }, [careerPlanFetched]);
};
