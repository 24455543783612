export const TARGET_FPS = 60;

export const ROLES = {
  instructor: 1,
  student: 2,
  admin: 3,
  multi_org_admin: 6,
  org_admin: 8,
};

export const ROLE_NAMES = {
  1: "Instructor",
  2: "Student",
  3: "Admin",
  6: "Multi-Org Admin",
  8: "Org Admin",
};
