import { useEffect, useRef } from "react";
import { randFloat } from "three/src/math/MathUtils";

/**
 * Automatically loop through the provided animation sequence.
 *
 * Currently this only supports linearly moving through the sequence, but this
 * could be extended to support other traversal methods such as randomly
 * selecting an animation action each time.
 */
export const useAnimationSequence = (actions, animationSequence) => {
  const animation = useRef();

  useEffect(() => {
    let index = -1;
    let prevAnimation;
    let prevClipConfig;
    let loopDuration;
    let timeoutId;

    const mixer = Object.values(actions)[0]?.getMixer();
    if (!mixer) {
      return;
    }

    const playNext = () => {
      index = (index + 1) % animationSequence.length;
      const clipConfig = animationSequence[index];

      animation.current = actions[clipConfig.name];
      if (clipConfig.clipDuration) {
        animation.current.setDuration(clipConfig.clipDuration);
      }

      if (prevClipConfig?.onLoop) {
        mixer.removeEventListener("loop", prevClipConfig.onLoop);
      }
      if (prevClipConfig?.onFinish) {
        mixer.removeEventListener("finish", prevClipConfig.onFinish);
        prevClipConfig.onFinish(loopDuration);
      }

      if (clipConfig.onLoop) {
        mixer.addEventListener("loop", clipConfig.onLoop);
      }
      if (clipConfig.onFinish) {
        mixer.addEventListener("finish", clipConfig.onFinish);
      }

      if (prevAnimation) {
        animation.current.crossFadeFrom(
          prevAnimation,
          prevClipConfig.crossFadeDuration ?? 0.5,
        );
      }
      animation.current.reset().play();

      prevAnimation = animation.current;
      prevClipConfig = clipConfig;
      loopDuration = clipConfig.loopDurationRange
        ? randFloat(
            clipConfig.loopDurationRange[0],
            clipConfig.loopDurationRange[1],
          )
        : animation.current.getClip().duration * 1000;

      timeoutId = setTimeout(playNext, loopDuration);
    };

    playNext();

    return () => {
      clearTimeout(timeoutId);

      for (const clipConfig of animationSequence) {
        if (clipConfig.onLoop) {
          mixer.removeEventListener("loop", clipConfig.onLoop);
        }
        if (clipConfig.onFinish) {
          mixer.removeEventListener("finish", clipConfig.onFinish);
        }
      }
    };
  }, [actions, animationSequence]);

  return animation.current;
};
