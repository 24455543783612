import { useTimer } from "@hooks/use-timer";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

// Default idle time is 10 seconds
const defaultIdleTime = 10 * 1000;

export const useDashIdleDetection = ({
  onIdle: onIdleHandler,
  speech,
  idleTime = defaultIdleTime,
  enabled = true,
}) => {
  const [idleSpeech, setIdleSpeech] = useState();
  const { isDone, setTimer, pause } = useTimer();

  // Timer receives time in seconds
  const resetTimer = () => setTimer(idleTime / 1000);

  const stopIdleDetection = () => {
    setIdleSpeech();
    pause();
  };

  // Using the idleTimer to detect user interaction
  // to close the speech and reset the timer
  const { start } = useIdleTimer({
    onAction: () => {
      setIdleSpeech();
      resetTimer();
    },
    debounce: 1000,
    disabled: !idleSpeech,
  });

  useEffect(() => {
    if (isDone) {
      onIdleHandler?.();
      setIdleSpeech(speech);
    }
  }, [isDone]);

  useEffect(() => {
    if (idleSpeech) {
      start();
    }
  }, [idleSpeech]);

  useEffect(() => {
    if (enabled) {
      resetTimer();

      const onSheetClosed = () => resetTimer();
      window.addEventListener("sheetclosed", onSheetClosed);

      return () => window.removeEventListener("sheetclosed", onSheetClosed);
    }
  }, [enabled]);

  useEffect(() => {
    const onSheetOpened = () => stopIdleDetection();
    window.addEventListener("sheetopened", onSheetOpened);

    return () => window.removeEventListener("sheetopened", onSheetOpened);
  }, [stopIdleDetection]);

  return {
    idleSpeech,
    setIdleSpeech,
    stopIdleDetection,
    startIdleDetection: resetTimer,
  };
};
