import { useCursor } from "@react-three/drei";
import { useLayoutEffect, useState } from "react";

const defaultHoverValues = {
  scale: 1.2,
};

export const useHoverAnimation = ({ values, enabled = true } = {}) => {
  const [isHovered, setIsHovered] = useState();
  const [animate, setAnimate] = useState();
  const [isEnabled, setIsEnabled] = useState(enabled);

  const variants = {
    hover: {
      ...defaultHoverValues,
      ...values,
    },
  };

  useCursor(isHovered);

  const onPointerOver = () => {
    setIsHovered(true);
  };

  const onPointerOut = () => {
    setIsHovered(false);
  };

  const getAttention = (options = {}) => {
    const { times, intervalMs } = { times: 5, intervalMs: 150, ...options };

    let counter = 0;
    let lastHoveredValue = true;

    const intervalId = setInterval(() => {
      if (!lastHoveredValue) {
        counter++;
      }

      if (counter === times) {
        window.clearInterval(intervalId);
      }

      lastHoveredValue = !lastHoveredValue;
      setIsHovered(() => lastHoveredValue);
    }, intervalMs);
  };

  useLayoutEffect(() => {
    if (isEnabled) {
      if (isHovered) {
        setAnimate("hover");
      } else {
        setAnimate();
      }
    }
  }, [isHovered]);

  return {
    animate,
    variants,
    isHovered,
    getAttention,
    onPointerOut,
    onPointerOver,
    enabled: setIsEnabled,
  };
};
