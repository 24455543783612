/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import { Billboard, ScreenSizer } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

export const Overlay = ({ enabled, layer = 1, onClick }) => {
  const ref = useRef();
  const layersMask = useRef();

  const { raycaster, size } = useThree();

  useEffect(() => {
    ref.current.traverse((x) => {
      x.layers.set(layer);
    });
  }, []);

  useEffect(() => {
    if (enabled) {
      layersMask.current = raycaster.layers.mask;
      raycaster.layers.disableAll();
      raycaster.layers.enable(layer);
    } else if (layersMask.current !== undefined) {
      raycaster.layers.mask = layersMask.current;
    }

    return () => {
      if (layersMask.current !== undefined) {
        raycaster.layers.mask = layersMask.current;
      }
    };
  }, [enabled, layer]);

  return (
    <Billboard ref={ref} layers={layer} onClick={onClick}>
      <ScreenSizer>
        <mesh scale={[size.width, size.height, 1]} position={[0, 0, 500]}>
          <planeGeometry args={[1, 1]} />
        </mesh>
      </ScreenSizer>
    </Billboard>
  );
};

Overlay.propTypes = {
  enabled: PropTypes.bool,
  layer: PropTypes.number,
  onClick: PropTypes.func,
};
