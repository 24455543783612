import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { ButtonVariants } from "@lib/animation.helpers";
import { buttonTracking } from "@lib/tracking.helpers";
import { useJourney } from "../hooks/use-journey";

import "./journey.footer.scss";

export const JourneyMapFooter = forwardRef(({ inverted }, ref) => {
  const { currentMap, requestMapChange } = useJourney();

  return (
    <div className="journey-map-footer" ref={ref}>
      {currentMap > 1 && (
        <motion.button
          variants={ButtonVariants}
          whileHover="hover"
          whileTap="tap"
          className="previous-map-button"
          onClick={() => requestMapChange(currentMap - 1)}
          {...buttonTracking("journey-map", "previous")}
          aria-label="Go previous map"
        >
          <FontAwesomeIcon
            icon="fa-regular fa-arrow-down"
            rotation={inverted ? 180 : 0}
          />
        </motion.button>
      )}
    </div>
  );
});

JourneyMapFooter.displayName = "Journey Map Footer";

JourneyMapFooter.propTypes = {
  inverted: PropTypes.bool,
};
