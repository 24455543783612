/* eslint react/no-unknown-property: 0 */
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import {
  CubicBezierCurve3,
  Matrix4,
  Quaternion,
  Vector2,
  Vector3,
} from "three";
import { randFloat } from "three/src/math/MathUtils";
import { useThrottledCallback } from "use-debounce";

import { clamp } from "@lib/app.helpers";

import { TARGET_FPS } from "src/constants";

const clampDistance = clamp(0, 1);

const getRandomVector = (camera, direction, bounds) => {
  const viewSize = new Vector2();

  // Start by getting a random y-coord within bounds
  const randomVector = new Vector3(0, randFloat(bounds.min.y, bounds.max.y), 0);

  // Now figure out the dimensions of the viewable plan at that y-coord
  camera.getViewSize(
    camera.position.distanceTo(
      randomVector.clone().projectOnVector(camera.position),
    ),
    viewSize,
  );
  // Get a random z-coord either within the provided bounds, or the view's
  // bounds
  randomVector.setZ(
    randFloat(
      Number.isNaN(bounds.min.z) ? 0 : bounds.min.z * direction.y,
      Number.isNaN(bounds.max.z) ? viewSize.y / 2 : bounds.max.z * direction.y,
    ) * direction.y,
  );

  // Now figure out the x-coord bounds given the y- and z-coords
  camera.getViewSize(
    camera.position.distanceTo(
      randomVector.clone().projectOnVector(camera.position),
    ),
    viewSize,
  );

  // Get an x-coord that is off screen
  return randomVector.setX((viewSize.x / 2 + 50) * direction.x);
};

export const useFollowPath = ({
  animation,
  appearanceWindow = [1000, 5000],
  bounds,
  directionVector = new Vector3(0, 0, 1),
  interpolant,
  model,
  speed,
  onComplete,
}) => {
  const timeSpentInAnimationRef = useRef(0);
  const lastPositionRef = useRef(new Vector3());
  const currentPositionRef = useRef(new Vector3());

  const { camera } = useThree();

  // position the model far away to start so it won't be visible until it starts
  // to follow a path
  const [position, setPosition] = useState(new Vector3(-100_000, 0, 0));
  const [[curve, curvePoints], setCurve] = useState([]);
  const [done, setDone] = useState(true);

  const frameDelta = useRef(0);
  const pathing = useRef({
    currentDistance: 0,
  });

  useEffect(() => {
    if (animation) {
      timeSpentInAnimationRef.current = 0;
    }
  }, [animation]);

  useEffect(() => {
    if (done) {
      if (onComplete) {
        onComplete();
      }

      const timeoutId = setTimeout(
        () => {
          pathing.current.currentDistance = 0;

          // Randomize the direction the path will come from in both the x- and
          // z-direction
          const direction = new Vector2(
            Math.random() < 0.5 ? -1 : 1,
            Math.random() < 0.5 ? -1 : 1,
          );

          const startVector = getRandomVector(camera, direction, bounds);
          const endVector = getRandomVector(
            camera,
            // end at the opposite sides
            direction.clone().multiplyScalar(-1),
            bounds,
          );

          // Choose a semi-random inflection point
          const midVec = startVector
            .clone()
            .lerp(endVector, 0.3 + Math.random() * 0.4);
          // Position the control points so they'll make reasonably smooth
          // curves
          const controlPoint1 = midVec.clone().setZ(startVector.z);
          const controlPoint2 = midVec.clone().setZ(endVector.z);

          const vectors = [
            startVector,
            controlPoint1,
            controlPoint2,
            endVector,
          ];

          setDone(false);
          setCurve([new CubicBezierCurve3(...vectors), vectors]);
        },
        randFloat(appearanceWindow[0], appearanceWindow[1]),
      );

      return () => clearTimeout(timeoutId);
    }
  }, [done]);

  const moveNextPathPoint = useThrottledCallback(() => {
    if (curve) {
      // Update model position to current distance
      const point = curve.getPointAt(
        clampDistance(pathing.current.currentDistance),
      );
      setPosition(point);

      if (interpolant) {
        // If we have an interpolant, we have some work to do...

        // Variables for convenience
        let timeSpentInAnimation = timeSpentInAnimationRef.current;
        const lastPosition = lastPositionRef.current;
        const currentPosition = currentPositionRef.current;
        const clipDuration = animation.getClip().duration;

        lastPosition.fromArray(interpolant.evaluate(timeSpentInAnimation));

        // Add time since last update
        timeSpentInAnimation += frameDelta.current;
        let diff = -1;
        if (timeSpentInAnimation >= clipDuration) {
          // Animation has looped. Since the animation's starting position is
          // less than it's ending position, we need to split the calculation of
          // distance traveled.
          diff = timeSpentInAnimation - clipDuration;
          // First we'll get the distance from the last position to the end of
          // the animation loop.
          timeSpentInAnimation = clipDuration;
        }
        currentPosition.fromArray(interpolant.evaluate(timeSpentInAnimation));
        // Calculate vector representing distance travelled.
        const difference = currentPosition.clone().sub(lastPosition);

        if (diff > -1) {
          // If we looped, add distance from start of animation to current time
          // in animation.
          difference.add(new Vector3().fromArray(interpolant.evaluate(diff)));
          timeSpentInAnimation = diff;
        }

        // Distance traveled should be scaled the same as the model itself.
        difference.multiply(model.scale);

        // Normalize the distance traveled by the length of the curve.
        pathing.current.currentDistance +=
          difference.length() / curve.getLength();

        timeSpentInAnimationRef.current = timeSpentInAnimation;
      } else {
        // No interpolant, so just move linearly.
        pathing.current.currentDistance += frameDelta.current;
      }

      // Reset frame delta accumulator
      frameDelta.current = 0;
      // Make sure we don't move too far!
      pathing.current.currentDistance = clampDistance(
        pathing.current.currentDistance,
      );
    }
  }, 1000 / TARGET_FPS);

  useFrame((_, delta) => {
    if (curve) {
      frameDelta.current += delta * (speed ?? 1);
      moveNextPathPoint();

      if (pathing.current.currentDistance >= 1) {
        setDone(true);
      }
    }
  }, 1);

  if (curve && model) {
    const lookAt = curve.getPointAt(
      clampDistance(pathing.current.currentDistance + 0.01),
    );

    // Orient the model correctly, i.e., simulate changing the forward vector.
    // For example, if the model's z-axis is inverted, this will flip the model
    // 180 degrees on the y-axis so it will actually be looking at the point
    // rather than having its back to the point.
    const adjust = new Quaternion().setFromUnitVectors(
      new Vector3(0, 0, 1).normalize(),
      directionVector.clone().normalize(),
    );
    const rotationMatrix = new Matrix4();
    rotationMatrix
      .lookAt(lookAt, model.position, model.up)
      // Update the forward vector (essentially)
      .multiply(new Matrix4().makeRotationFromQuaternion(adjust));
    model.quaternion.setFromRotationMatrix(rotationMatrix);
  }

  return { position, curve, curvePoints };
};
