/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useRef } from "react";

import {
  DashTheHedgehog,
  FishingPole,
  Obstacle,
  Stream,
} from "@pages/journey-map/3d-models";
import {
  JourneyButton3D,
  JourneyPin,
  Overlay,
} from "@pages/journey-map/components";
import { NonBlockingA11y } from "@pages/journey-map/components/non-blocking.a11y";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import {
  useDashIdleDetection,
  useDashTheHedgehog,
  useHoverAnimation,
  useJourney,
} from "@pages/journey-map/hooks";
import { useIntro } from "@pages/journey-map/hooks/use-intro";

const dashInitialPosition = [70, 28, -150];
const matchesPathPoint = [0, 25, 210];

const mainPathPoints = [
  dashInitialPosition,
  [60, 33, -100],
  [40, 38, -70],
  [20, 38, -45],
  [15, 38, -40],
  [5, 25, -40],
  [0, 25, -30],
  [-15, 20, -30],
  [-15, 14, -30],
  [-25, 12, -30],
  [-30, 12, -25],
  [-35, 5, -25],
  [-45, 5, -25],
  [-50, 2, -25],
  [-50, 3, -20],
  [-80, 3, -5],
  [-90, 3, 60],
  [-70, 2, 130],
  [-60, 2, 140],
  [-50, 7, 140],
  [-50, 7, 150],
  [-40, 10, 160],
  [-30, 12, 170],
  [-10, 25, 200],
  matchesPathPoint,
];

export const MapTwo = ({ onMapAnimationComplete, onMoveToStep }) => {
  const dashRef = useRef();

  const { data: journeySteps } = useJourneySteps();
  const { nextMapUnlocked } = useJourney();
  const matchesHoverAnimation = useHoverAnimation();

  // References for the jumping points, they're listed from back to front
  const firstJumpDown = useRef();
  const secondJumpDown = useRef();
  const thirdJumpDown = useRef();
  const firstJumpUp = useRef();
  const secondJumpUp = useRef();
  const thirdJumpUp = useRef();

  const { closeIntro, dashProps, speech, viewedIntro } = useIntro({
    introKey: "map2",
    initialPosition: nextMapUnlocked ? matchesPathPoint : dashInitialPosition,
    mainPathPoints,
    text: "Let's catch a new career match!",
  });
  const dash = useDashTheHedgehog({
    ref: dashRef,
    obstacles: [
      firstJumpDown.current,
      secondJumpDown.current,
      thirdJumpDown.current,
      firstJumpUp.current,
      secondJumpUp.current,
      thirdJumpUp.current,
    ],
    ...dashProps,
  });
  const { idleSpeech, stopIdleDetection } = useDashIdleDetection({
    onIdle: () => !nextMapUnlocked && matchesHoverAnimation.getAttention(),
    speech: {
      animationDuration: 5000,
      text: nextMapUnlocked
        ? "You're pointed in the right direction. Let's start your pathway."
        : "Quill you find your match!",
      pinConfig: {
        size: nextMapUnlocked ? [200, 40] : [140, 40],
        fontSize: 10,
      },
    },
    enabled: !!viewedIntro,
  });

  const goToMatches = () => {
    stopIdleDetection();

    dash.setDestination({
      distance: 1,
      onDestinationReached: () => {
        onMoveToStep(journeySteps.stepsByCode.MATCHES);
        onMapAnimationComplete?.();
      },
    });
  };

  return (
    <group>
      <Overlay enabled={!viewedIntro} onClick={closeIntro} />

      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={75}
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
        speech={speech ?? idleSpeech}
      />

      <NonBlockingA11y
        role="button"
        description="career matches"
        actionCall={goToMatches}
        active={viewedIntro}
      >
        <JourneyButton3D
          position={[30, 48, 200]}
          onClick={goToMatches}
          hoverAnimation={matchesHoverAnimation}
        >
          <JourneyPin
            size={[50, 20]}
            position={[0, 100, 0]}
            rotation={[-0.4, Math.PI * -0.05, 0]}
            text="Matches"
            fontSize={10}
          />
          <FishingPole scale={80} rotation={[0, Math.PI * 0.2, 0]} />
        </JourneyButton3D>
      </NonBlockingA11y>

      <Stream
        position={[20, 0, 50]}
        scale={90}
        rotation={[0, Math.PI * 0.6, 0]}
      />

      {/* Obstacle to trigger jump animation */}
      <Obstacle
        ref={firstJumpDown}
        dimensions={[20, 20, 20]}
        position={[-10, 45, -20]}
      />
      <Obstacle
        ref={secondJumpDown}
        dimensions={[20, 20, 20]}
        position={[-35, 45, -20]}
      />
      <Obstacle
        ref={thirdJumpDown}
        dimensions={[20, 20, 20]}
        position={[-50, 45, -20]}
      />

      <Obstacle
        ref={firstJumpUp}
        dimensions={[20, 20, 20]}
        position={[-40, 10, 165]}
      />
      <Obstacle
        ref={secondJumpUp}
        dimensions={[20, 20, 20]}
        position={[-20, 30, 190]}
      />
      <Obstacle
        ref={thirdJumpUp}
        dimensions={[20, 20, 20]}
        position={[0, 40, 175]}
      />
    </group>
  );
};

MapTwo.propTypes = {
  onMapAnimationComplete: PropTypes.func,
  onMoveToStep: PropTypes.func,
};
